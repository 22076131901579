import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import ContactPageTemplate from "../components/ContactPageTemplate";
import Layout from "../components/Layout";

const ContactPage = (props) => {
  const {
    data: {
      markdownRemark: {
        frontmatter: { title, subtitle, meta_title, meta_description },
      },
    },
  } = props;

  return (
    <Layout>
      <ContactPageTemplate
        title={title}
        subtitle={subtitle}
        meta_title={meta_title}
        meta_description={meta_description}
      />
    </Layout>
  );
};

ContactPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ContactPage;

export const contactPageQuery = graphql`
  query ContactPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        subtitle
        meta_title
        meta_description
        heading
      }
    }
  }
`;
